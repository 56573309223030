#slider {
  position:relative;
  padding:0;
}
#slider .owl-item {
  padding:15px;
}
#slider .item a {
  border:2px solid #ffffff;
  box-shadow: 0 0 6px #666; /* all latest browser */
  -moz-box-shadow: 0 0 6px #666; /* Firefox older version*/
  -webkit-box-shadow: 0 0 3px #666;
  width:100%;
  float:left;
  transition: all .3s ease;
}
#slider .item .desc {
  background:#1b365f;
  color:#fff;
  padding:25px;
}
#slider .item .desc h3 {
  margin:0;
  padding:0;
  font-size:21px;
}
.item .line {
  padding:25px 0 40px;
  text-align:center;
  display:block;
  width:84px;
  margin:0 auto;
  position:relative;
}
.item .line::before,
.item .line::after{
  content:"";
  width:100px;
  height:1px;
  background:#ffffff;
  position:absolute;
  top:34px;
}
.item .line::before {
  left:-115px;
}
.item .line::after {
  right:-107px;
}
.item .line .square {
  width:20px;
  height:20px;
  background:#eb8130;
  margin:0 8px 0 0;
  float:left;
}
.item table {
  text-align:left;
}
.item table tr td:first-child {
  color:#eb8130;
}
.item table tr td {
  padding:4px 10px 4px 0;
}

#slider .item a:hover,
#slider .item a:focus {
  text-decoration:none;
  box-shadow:0 0 12px #333;
}


#slider .owl-buttons .owl-prev,
#slider .owl-buttons .owl-next {
  color:#eb8130;
  position:absolute;
  top:200px;
  width:80px;
  height:80px;
  display:block;
  border:1px solid #eb8130;
  transition: all .3s ease;
}
#slider .owl-buttons .owl-prev {
  left:-95px;
}
#slider .owl-buttons .owl-prev::before,
#slider .owl-buttons .owl-next::before{
  font-family: FontAwesome;
  color:#eb8130;
  position:absolute;
  text-align:center;
  font-size:30px;
  left:0;
  right:0;
  margin:28px auto 0;
  transition: all .3s ease;
}
#slider .owl-buttons .owl-prev::before {
  content:"\f053";
}
#slider .owl-buttons .owl-next::before {
  content:"\f054";
  left:4px;
}
#slider .owl-buttons .owl-next {
  right:-95px;
}

#slider .owl-buttons .owl-prev:hover,
#slider .owl-buttons .owl-prev:focus,
#slider .owl-buttons .owl-next:hover,
#slider .owl-buttons .owl-next:focus {
  border:1px solid #eb8130;
  background:#eb8130;
}
#slider .owl-buttons .owl-prev:hover::before,
#slider .owl-buttons .owl-next:hover::before {
  color:#fff;
}
