$blue:               #1b365f;
$blueShadow:         #152036;

$orange:              #eb8130;
$orangeShadow:        #98470a;

$red:                 #e73232;

$gray:                #c7c4c5;
$grayLight:           #eeeeee;
$grayShadow:          #a1a5a7;

$fontColor:           #152036;

$fontBase:            'Open Sans', sans-serif;

// RESPONSIVE

$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;

$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;

$screen-md: 992px !default;
$screen-md-min: $screen-md !default;

$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;

$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;