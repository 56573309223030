/* ==============================================
    NIEUWE WONINGEN
================================================*/
#woningen {
  h2 {
    padding-bottom: 40px;
  }
}

/* ==============================================
    MEEST BEKEKEN
================================================*/
#meestbekeken h2 {
  padding-top: 40px;
}

.colWoning {
  padding: 0;
  margin: 0;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.colWoning:first-child {
  border-left: 1px solid #fff;
}

.colWoning .imageInner {
  display: block;
  width: 100%;
  height: 350px;
  overflow: hidden;
  position: relative;
}

.colWoning a {
  transition: all .3s ease;
}

.colWoning a:hover,
.colWoning a:focus {
  text-decoration: none;
}

.colWoning .imageInner .image {
  display: table;
  position: relative;
  height: 350px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.colWoning .imageInner .image .desc {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  z-index: 100;
  color: #fff;
}

.colWoning .imageInner .image .desc h3 {
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  position: relative;
  display: inline;
}

.colWoning .imageInner .image .desc h3::before,
.colWoning .imageInner .image .desc h3::after {
  content: "";
  width: 50px;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 16px;
}

.colWoning .imageInner .image .desc h3::before {
  left: -75px;
}

.colWoning .imageInner .image .desc h3::after {
  right: -75px;
}

.colWoning .imageInner .image img {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0
}

.rowOdd .colWoning:nth-of-type(even) .imageInner .image::after,
.colWoning .imageInner .image::after {
  background: rgba(27, 54, 95, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all .3s ease;
}

.rowOdd .colWoning .imageInner .image::after,
.colWoning:nth-of-type(even) .imageInner .image::after {
  background: rgba(235, 129, 48, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all .3s ease;
}

.rowOdd .colWoning:nth-of-type(even) a:hover .imageInner .image::after,
.colWoning a:hover .imageInner .image::after {
  background: rgba(0, 0, 0, 0.6);
}

.rowOdd .colWoning a:hover .imageInner .image::after,
.colWoning:nth-of-type(even) a:hover .imageInner .image::after {
  background: rgba(0, 0, 0, 0.6);
}