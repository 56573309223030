@media (max-width: $screen-sm-max) {
  .navbar-default {
    .container {
      width:100%;
    }
  }
  .listHouses {
    ul {
      li {
        a {
          &:after {
            content: "";
            font-family: FontAwesome;
            position: absolute;
            right: 5px;
            width: 0;
            height: 0;
            display: block;
          }
          &:hover,
          &:focus {
            &:before {
              content: "";
              height: 0;
              width: 0;
            }
          }
          .description {
            .address {
              max-width:200px;
              h2 {
                font-size: 18px;
              }
              h3 {
                line-height:18px;
              }
            }
            .price {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .filter {
    .group {
      margin-top: 30px;
      .title {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

}

@media (min-width: $screen-sm-min) {

}




