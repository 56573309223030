/* ==============================================
    VIEWER #LIST #MAP
================================================*/

.viewer {
  float: left;
  width: 100%;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 0px 0 30px 0;
  .search {
    padding: 13px 0;
    float: right;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    float: right;
    li {
      float: left;
      margin: 0 0 0 25px;
      a {
        padding: 12px 0 14px 25px;
        float: left;
        position: relative;
        color: $gray;
        font-size: 16px;
        &:before {
          position: absolute;
          left: 0;
          font-weight: normal;
          font-family: FontAwesome;
          font-size: 16px;
          color: $gray;
        }
        &:hover,
        &:focus {
          color: $orange;
          text-decoration: none;
          &:before {
            color: $orange;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            background: $orange;
            width: 100%;
            height: 3px;
          }
        }
      }
      &:first-child {
        a {
          &:before {
            content: "\f0ca";
            top: 13px;
          }
        }
      }
      &:last-child {
        a {
          &:before {
            content: "\f278";
            top: 13px;
          }
        }
      }
      &.active {
        a {
          color: $orange;
          &:before {
            color: $orange;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            background: $orange;
            width: 100%;
            height: 3px;
          }
        }
      }
    }
  }
}

/* ==============================================
    LIST HOUSES
================================================*/
#mainCol {
  min-height: 652px;
  position: relative;
}

.listAanbod {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: block;
      float: left;
      width: 100%;
      border-bottom: 1px solid #eee;
      padding: 0 0 30px 0;
      margin: 0 0 30px 0;
      a {
        display: block;
        width: 100%;
        float: left;
        position: relative;
        .image {
          width: 300px;
          height: 190px;
          margin-top: 3px;
          float: left;
          overflow: hidden;
          background: $orange;
          position: relative;
          img {
            transition: all 0.3s ease;
            max-width: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .description {
          float: left;
          margin-left: 30px;
          .address {
            height: 45px;
            margin: 0 0 30px 0;
            float: left;
            width: 100%;
            h2, h3 {
              text-transform: inherit;
              color: $orange;
            }
            h2 {
              font-size: 21px;
              font-weight: bold;
              padding: 0 0 8px 0;
            }
            h3 {
              font-size: inherit;
              font-weight: normal;
              margin: 0;
              padding: 0;
            }
          }
          .price {
            color: $blue;
            font-weight: bold;
            font-size: 21px;
            margin: 0 0 20px 0;
          }
          .details {
            .table {
              margin: 0;
              tr {
                td {
                  border: 0;
                  padding: 3px 0;
                  color: $blue;
                  &:last-child {
                    font-weight: bold;
                  }

                  &:first-child {
                    width: 110px;
                  }
                }
              }
            }
          }
        }
        &:before {
          transition: all 0.5s ease;
        }
        &:after {
          content: "\f002";
          font-family: FontAwesome;
          position: absolute;
          right: 5px;
          bottom: 0;
          font-size: 30px;
          width: 30px;
          height: 30px;
          display: block;
        }

        &:hover,
        &:focus {
          .image {
            img {
              -webkit-transform: scale(1.2);
              max-width: 104%;
              opacity: 0.6;
            }
          }
          &:before {
            content: "";
            height: 3px;
            width: 40px;
            position: absolute;
            right: 0;
            bottom: -32px;
            background: $orange;
          }
          &:after {
            color: $orange;
          }
        }
      }
      &.verkocht,
      &.verhuurd,
      &.optie {
        a {
          .image {
            background: white;
            img {
              opacity: 0.2;
            }
            .overlay {
              display: table;
              width: 100%;
              height: 190px;
              div {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                font-size: 16px;
                color: white;
                position: relative;
                span {
                  padding: 6px 18px 8px 18px;
                  display: inline-block;
                  background: $red;
                  position: relative;
                  &:after,
                  &:before {
                    content: "";
                    width: 60px;
                    height: 1px;
                    top: 20px;
                    background: $red;
                    position: absolute;
                  }
                  &:before {
                    left: -65px;
                  }
                  &:after {
                    right: -65px;
                  }
                }
              }
            }
          }
          .description {
            opacity: 0.4;
          }

          &:hover,
          &:focus {
            .image {
              img {
                opacity: 0.3;
              }
            }
          }
        }
      }
      &.optie a .image .overlay {
        div span {
          background: $orange;
          &:after,
          &:before {
            background: $orange;
          }
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/* ==============================================
    FILTER
================================================*/
.result {
  float: left;
  width: 100%;
  background: $grayLight;
  padding: 15px;
  position: relative;
  .close {
    position: absolute;
    right: 7px;
    top: 5px;
    font-size: 14px;
  }
}

.filter {
  .group {
    font-size: 14px;
    margin-top: 15px;
    float: left;
    width: 100%;
    span {
      &.title {
        font-weight: bold;
      }
    }
    fieldset {
      .radio {
        label {
          &:before {
            top: 4px;
          }
          &:after {
            top: 7px;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .btn {
      border-radius: 0;
      padding: 10px 12px;
    }
    .dropdown-select {
      margin: 10px 0 0 0;
      float: left;
      width: 100%;
    }

    .vanaf,
    .tot {
      margin: 10px 0 0 0;
      float: left;
      width: 100%;
      .bootstrap-select {
        &.btn-group {
          .dropdown-toggle {
            .filter-option {
              padding-left: 40px;
              position: relative;
              font-size: 14px;
              &:before {
                content: "van";
                position: absolute;
                font-size: 14px;
                left: 0;
                top: 1px;
                color: $gray;
              }
            }
          }
        }
      }
    }
    .tot {
      margin-bottom: 0px;
      .bootstrap-select {
        &.btn-group {
          .dropdown-toggle {
            .filter-option {
              &:before {
                content: "tot";
              }
            }
          }
        }
      }
    }
    .dropdown-select {
      .dropdown-menu {
        border-radius: 0;
      }
    }
    &:first-child {
      margin-top: 15px;
    }
  }
}

.bootstrap-select {
  &.btn-group {
    .dropdown-toggle {
      .caret {
        border: 0;
        margin-top: 0;
        top: 0;
        width: 14px;
        height: 15px;
        &:before {
          content: "\f078";
          color: $blueShadow;
          font-family: FontAwesome;
          position: relative;
          top: 11px;
          right: 0;
          width: 14px;
          height: 15px;
        }
      }
    }
  }
  &:active,
  &:focus {
    box-shadow: none;
  }
}

select {
  &:focus,
  &:hover {
    box-shadow: none;
  }
}

.mapAanbod, .mapDetails {
  width: 100%;
  height: 800px;
  margin: 0 0 55px;
  border: 1px solid #ccc;
}

.mapDetails {
  height: 500px;
}

.googleInfo {
  p {
    margin: 0;
  }
}

#search {
  .input-group {
    padding: 10px 0 0;
  }

  input {
    box-shadow: none;
    border-radius: 0;
    padding: 10px 12px;
    height: 42px;
  }

  .btn {
    border-radius: 0;
    height: 42px;
  }
}