@media (max-width: $screen-md-max) {
  header {
    &.small {
      height:225px;
      .rowWrapper {
        height: 225px;
        .rowInner {
          .logo {
            max-width:300px;
            margin:0 auto;
            img {
              max-width:100%;
            }
          }
        }
      }
    }
  }
  .navbar-default {
    top:225px;
  }
  .aanbod {
    .overview {
      .description {
        margin-left:0;
        margin-top:30px;
      }
    }
  }
  .sidebar {
    .contact {
      h4, a {
        font-size:15px;
      }
    }
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

}

@media (min-width: $screen-md-min) {
  #sidebar-filter.affix-top {
    position: static;
    width:212px;
  }
  #sidebar-filter.affix-bottom {
    position: absolute;
    width:212px;
  }

  #sidebar-filter.affix {
    position: fixed;
    top:90px;
    width:212px;
  }
}