footer {
  background:#1b365f;
  color:#ffffff;
  padding:55px 0;
  h3 {
    margin:0;
    padding:0 0 30px 0;
    color:#eb8130;
    font-size:30px;
    font-weight:300;
  }
  .about {
    padding-right:60px;
  }
  ul {
    &.contactgegevens {
      margin:0;
      padding:0 0 0 65px;
      list-style:none;
      li {
        margin:0 0 25px 0;
        position:relative;
        a {
          color:#fff;
        }
        &:first-child,
        &:last-child,
        &:nth-child(2) {
          &:before {
            font-family: FontAwesome;
            color:#152036;
            position:absolute;
            width:40px;
            height:80px;
          }
        }
        &:first-child {
          &:before {
            content:"\f041";
            left:-62px;
            top:30px;
            font-size:46px;
          }
        }
        &:last-child {
          &:before {
            content:"\f0e0";
            left:-66px;
            top:14px;
            font-size:32px;
          }
        }
        &:nth-child(2) {
          &:before {
            content:"\f095";
            left:-65px;
            top:18px;
            font-size:42px;
          }
        }
      }
    }
  }
}