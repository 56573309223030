header {
  background-repeat:no-repeat;
  background-size: cover;
  table-layout: fixed;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position:relative;
  .overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background:rgba(27,54,95,0.6);
    z-index:0
  }
  .rowWrapper {
    height: 100vh;
    display: table;
    table-layout: fixed;
    width: 100%;
    z-index:10;
    position:relative;
    text-align:center;
    .rowInner {
      display: table-cell;
      vertical-align: middle;
      padding:0 0 50px 0;
      width: 100%;
      z-index:10;
      position:relative;
      .line {
        padding:60px 0;
        text-align:center;
        display:block;
        width:84px;
        margin:0 auto;
        position:relative;
        &:before,
        &:after {
          content:"";
          width:100px;
          height:1px;
          background:#ffffff;
          position:absolute;
          top:69px;
        }
        &:before {
          left:-115px;
        }
        &:after {
          right:-107px;
        }
        .square {
          width:20px;
          height:20px;
          background:#eb8130;
          margin:0 8px 0 0;
          float:left;
        }
      }
      .slogan {
        h1 {
          color:#ffffff;
          text-transform:uppercase;
          font-size:30px;
          font-weight:400;
        }
      }
    }
  }
  a {
    &.btnHeader {
      position:absolute;
      text-align:center;
      left:0;
      right:0;
      bottom:40px;
      margin:0 auto;
      width:200px;
      display:inline-block;
      font-weight:300;
      z-index:10;
      color:#ffffff;
      text-transform: uppercase;
      text-decoration: none;
      i {
        font-size:24px;
      }
      &:hover,
      &:focus {
        color:#eb8130;
      }
    }
  }
  &.small {
    height:325px;
    width:100%;
    background: scroll top center;
    background-size: cover;
    overflow:hidden;
    .rowWrapper {
      height: 325px;
      .rowInner {
        padding:0;
      }
    }
    .overlay {
      height:325px;
      background:rgba(0,0,0,0.1);
    }
  }
}