@media (min-width: $screen-lg-min) {
  #sidebar-filter.affix-top {
    width:262px !important;
  }
  #sidebar-filter.affix-bottom {
    width:262px !important;
  }

  #sidebar-filter.affix {
    width:262px !important;
  }
}