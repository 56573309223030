.navbar-default {
  position:absolute;
  top:325px;
  width:100%;
}
.navbar-default.fixed {
  position:fixed;
  width:100%;
  top:0;
}
#menu {
  background:$blue;
  margin:0;
  #navbar {
    ul {
      &.nav {
        li {
          a {
            color:#ffffff;
            border-top:5px solid $blueShadow;
            text-transform:uppercase;
            font-size:16px;
          }
        }
      }
      &.navbar-left {
        li {
          &.active {
            a {
              background:transparent;
              border-top:5px solid $orange;
              color:$orange;
              &:hover,
              &:focus {
                border-top:5px solid $orange;
              }
            }
          }
          a {
            &:hover,
            &:focus {
              color:$orange;
              background:transparent;
              border-top:5px solid $orange;
            }
          }
        }
      }
      &.navbar-right {
        margin-right:0;
        li {
          a {
            padding:15px 8px;
            &:hover,
            &:focus {
              background:transparent;
              text-decoration:underline;
            }
          }
          &.active {
            a {
              background:transparent;
              text-decoration:underline;
            }
          }
        }
      }
    }
  }
  &:before {
    content:"";
    width:100%;
    height:5px;
    background:#152036;
    position:absolute;
    top:0;
  }
}