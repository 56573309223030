/* ==============================================
    SIDEBAR
================================================*/
.col-sidebar {
  max-width: 300px;
}

.sidebar {
  margin: 0 0 55px 0;
  float: left;
  width: 100%;
  max-width: 300px;
  .goBack {
    height: 45px;
    margin: 0 0 30px 0;
    display: block;
    width: 100%;
    float: left;
    a {
      background:$grayLight;
      border:1px solid $gray;
      color:$orange;
      display:block;
      float:left;
      padding:5px 15px;
      i {
        font-size: 18px;
        margin-top: 5px;
        margin-left:0;
        text-align:left;
        margin-right: 10px;
        display: block;
        float: left;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        color: $blueShadow;
      }
    }
  }
  .interested {
    width:100%;
    float:left;
    border:1px solid $grayLight;
    padding:15px;
  }
  .smallTxt {
    float: left;
    width: 100%;
    padding-bottom:0px;
    h3 {
      margin: 0;
      padding: 0 0 3px 0;
      color: $orange;
      font-weight: bold;
      font-size: 21px;
    }
    p {
      color: $blueShadow;
      margin: 0;
    }
  }
  .contact {
    width: 100%;
    float: left;
    background:$grayLight;
    border: 1px solid $grayLight;
    padding: 18px 16px 12px 18px;
    margin: 15px 0 0 0;
    h4, a {
      color: $blueShadow;
      font-weight: bold;
      font-size: 18px;
      text-decoration: none;
      margin: 0;
      padding: 0 0 5px 0;
      &:last-child {
        padding: 0;
        &:hover {
          color: $orange;
        }
      }
    }
  }
  .padding_bottom {
    padding:0 0 55px 0;
  }
  .margin_bottom {
    margin:0 0 55px 0;
  }
}

/* ==============================================
    WONING
================================================*/
.aanbod {
  .address {
    height: 45px;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    h2, h3 {
      text-transform: inherit;
      color: $orange;
    }
    h2 {
      font-size: 21px;
      font-weight: bold;
      padding: 0 0 8px 0;
    }
    h3 {
      font-size: inherit;
      font-weight: normal;
      margin: 0;
      padding: 0;
    }
  }

  .overview {
    width: 100%;
    float: left;
    margin: 0 0 55px 0;
    .image {
      float: left;
      display: block;
      overflow: hidden;
      max-width: 530px;
      height: 340px;
      max-height: 340px;
    }
    .description {
      float: left;
      max-width: 280px;
      margin-left: 30px;
      h3 {
        margin: 0;
        color: $blueShadow;
        padding: 0 0 5px 0;
      }
    }
  }

  .tabs {
    float: left;
    width: 100%;
    .nav-tabs {
      position: relative;
      height: 50px;
      &:before,
      &:after {
        content: "";
        position: absolute;
        border: 0;
        border-top: 1px solid $orange;
        border-bottom: 1px solid $orange;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;
      li {
        margin: 0 30px 0 0;
        a {
          border: 0;
          padding: 13px 0px 14px 0px;
          &:hover,
          &:focus {
            border: 0;
            border-bottom: 3px solid $orange;
            background: transparent;
            color: $orange;
          }
        }
        &.active {
          a {
            border: 0;
            color: $orange;
            border-bottom: 3px solid $orange;
            &:hover,
            &:focus {
              border: 0;
              color: $orange;
              border-bottom: 3px solid $orange;
              background: transparent;
            }
          }
        }
      }
    }
    .tab-content {
      margin-top: 30px;
      margin-bottom: 55px;
      .table {
        margin-top: 5px;
        td {
          border: 0;
          padding: 10px 6px;
          &:first-child {
            color: $gray;
            max-width: 130px;
            width: 130px;
          }
        }
      }
      form {
        h4 {
          margin-top: 15px;
          padding-top:15px;
          border-top:1px solid #eee;
          float: left;
          width: 100%;
          padding-bottom: 8px;
          margin-bottom: 15px;
          &:first-child {
            border-top:0;
          }
        }
        .col-sm-1 {
          padding-right: 0;
        }
        .day,
        .month {
          max-width: 60px;
        }
        .year {
          max-width: 120px;
        }
        [class*="col-"] {
          padding-left: 8px;
          &:first-child {
            padding-left: 15px;
          }
        }
        .form-group {
          margin-bottom: 8px;
          .form-control {
            border-radius: 0;
            height: 44px;
            background: #f9f9f9;
            -webkit-transition: all 400ms ease-out;
            -moz-transition: all 400ms ease-out;
            -o-transition: all 400ms ease-out;
            -ms-transition: all 400ms ease-out;
            transition: all 400ms ease-out;
            &:focus {
              box-shadow:none;
              border-color:$blueShadow;
              background:#fff;
            }
          }
          textarea {
            &.form-control {
              height:250px;
              resize:none;
            }
          }
        }
        label {
          text-align: left;
          color: $blueShadow;
          font-weight: normal;
          font-size: 14px;
          position: relative;
          padding-top: 10px;
          &.required {
            position: relative;
            display: inline;
            padding-right: 10px;
            font-weight: bold;
            span {
              position: relative;
              display: inline-block;
              padding-right: 10px;
              &:after {
                content: "\f069";
                font-size: 8px;
                font-family: FontAwesome;
                color: $orange;
                position: absolute;
                right: 0;
                top: -4px;
              }
            }
          }
        }
        .send {
          padding-right:0;
        }
      }
    }
  }
}

.radioContainer {
  padding: 2px 0 10px 0;
  .radio-inline {
    padding-top: 0;
    label {
      padding-top: 0 !important;
    }
  }
}

.radio {
  padding-left: 22px;
}

.radio label {
  display: inline-block;
  position: relative;
  padding-left: 5px;
}

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 3px;
  margin-left: -20px;
  border: 1px solid $blueShadow;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 6px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: $blueShadow;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
  opacity: 0;
}

.radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

#foto_slider {
  .thumbnail {
    margin:0 5px;
    border-radius:0;
    display:table;
    height:100%;

    a {
      background:$orange;
      display:block;
      position:relative;

      img {
        transition: all 0.5s ease;
      }
      &:hover,
      &:focus {
        img {
          opacity: 0.6;
        }
      }
    }
    &:hover,
    &:focus {
      border-color:$orange;
    }
  }
  .owl-buttons {
    .owl-prev,
    .owl-next {
      position: absolute;
      width: 32px;
      height: 32px;
      top: -44px;
      padding-top: 0;
      text-align: center;
      color: white;
      background: $orange;
      &:hover,
      &:focus {
        background: $blueShadow;
      }
    }
    .owl-prev {
      right: 40px;
    }

    .owl-next {
      right: 5px;
      padding-left: 2px;
    }
    .owl-next:before,
    .owl-prev:before {
      font-family: fontAwesome;
      font-size: 29px;
      color: white;
      content: '\f104';
      display: inline-block;
      width: 32px;
      height: 32px;
      padding-top:2px;
    }

    .owl-next:before {
      content: '\f105';
    }
  }
}
.share {
  float:left;
  margin-top:25px;
  margin-left:25px;
}
.share_text,
.addthis_inline_share_toolbox {
  float:left;
  width:100%;
}
.share_text {
  margin-bottom:4px;
}



