body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
  line-height: 26px;
}

p {
  margin: 0 0 25px 0;
  font-size: 14px;
}

a {
  color: $orange;
}

a:hover,
a:focus {
  outline: none;
}

h1, h2 {
  text-transform: uppercase;
  font-size: 44px;
  margin: 0;
  color: #eb8130;
  font-weight: 300;
}

h3 {
  color: $orange;
  font-weight: bold;
  font-size: 21px;
}

h4 {
  color: $orange;
  font-size: 16px;
  margin: 0;
  padding: 0 0 5px 0;
}

.status {
  background: $blue;
  padding: 10px 15px;
  border: 1px solid $blueShadow;
  display: block;
  font-size: 20px;
  color: white;
  text-align: center;
  margin-bottom: 10px;
}

/* ==============================================
    BUTTONS
================================================*/
.btnOrange {
  display: inline-block;
  background: $orange;
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  position: relative;
  border: 1px solid $orangeShadow;
  &:hover,
  &:focus {
    background: $blueShadow;
    color: white;
    text-decoration: none;
    border: 1px solid $blue;
  }
  &.plus {
    padding: 10px 45px 10px 15px;
    &:after {
      content: "\f055";
      font-family: FontAwesome;
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 16px;
      color: #fff;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .3s;
      transition: .3s;
    }
    &:hover {
      &:after {
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
  &.arrow {
    padding: 10px 30px 10px 15px;
    &:after {
      content: "\f054";
      font-family: FontAwesome;
      position: absolute;
      right: 15px;
      top: 11px;
      font-size: 12px;
      color: #fff;
      -webkit-transition: .3s;
      transition: .3s;
    }
    &:hover {
      &:after {
        right: 12px;
      }
    }
  }
  &.pdf {
    padding: 10px 40px 10px 15px;
    &:after {
      content: "\f1c1";
      font-family: FontAwesome;
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 14px;
      color: #fff;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .3s;
      transition: .3s;
    }
    &:hover {
      &:after {
        content: "\f019";
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
        font-family: FontAwesome;
      }
    }
  }
}

/* ==============================================
    TITLE
================================================*/

section {
  &.title {
    margin: 55px 0 0 0;
    padding: 45px 0 45px 0;
    h1 {
      float: left;
      margin: 0;
      padding: 0 30px 0 0;
      position: relative;
      &:after {
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        right: 0;
        top: 23px;
        background: $blue;
      }
    }
    h2 {
      margin: 0 0 0 4px;
      padding: 12px 0 0 30px;
      float: left;
      color: #1b365f;
      text-transform: lowercase;
      font-size: 20px;
      font-weight: 300;
      position: relative;
      &:before,
      &:after {
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0;
        top: 23px;
        background: $blue;
      }
      &:after {
        left: 9px;
      }
    }
  }
}

/* ==============================================
    SECTION
================================================*/
section.center {
  text-align: center;
}

section.center h2 {
  text-transform: uppercase;
  font-size: 44px;
  padding: 55px 0;
  margin: 0;
  color: #eb8130;
  font-weight: 300;
}

@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}

section#text {
  padding: 95px 0 25px;

  h1 {
    margin-bottom: 25px;
  }
}

/* ==============================================
    SIDEBAR
================================================*/
.affix-top,
.affix {
  position: static;
}

.affix-bottom {
  position: relative;
}

/* ==============================================
    FORM
================================================*/

form.regular {
  h4 {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #eee;
    float: left;
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 15px;
    &:first-child {
      border-top: 0;
    }
  }
  .col-sm-1 {
    padding-right: 0;
  }
  .day,
  .month {
    min-width: 60px;
  }
  .year {
    min-width: 120px;
  }
  [class*="col-"] {
    padding-left: 8px;
    &:first-child {
      padding-left: 15px;
    }
  }
  .form-group {
    margin-bottom: 8px;
    margin-right: 0;
    .form-control {
      border-radius: 0;
      height: 44px;
      background: #f9f9f9;
      -webkit-transition: all 400ms ease-out;
      -moz-transition: all 400ms ease-out;
      -o-transition: all 400ms ease-out;
      -ms-transition: all 400ms ease-out;
      transition: all 400ms ease-out;
      &:focus {
        box-shadow: none;
        border-color: $blueShadow;
        background: #fff;
      }
    }
    textarea {

      &.form-control {
        height: 150px;
        max-width: 361px;
        margin-left: 15px;
        resize: none;
      }
    }
  }
  label {
    text-align: left;
    color: $blueShadow;
    font-weight: normal;
    font-size: 14px;
    position: relative;
    padding-top: 10px;
    &.required {
      position: relative;
      display: inline;
      padding-right: 10px;
      font-weight: bold;
      span {
        position: relative;
        display: inline-block;
        padding-right: 10px;
        &:after {
          content: "\f069";
          font-size: 8px;
          font-family: FontAwesome;
          color: $orange;
          position: absolute;
          right: 0;
          top: -4px;
        }
      }
    }
    &.control-label {
      text-align: left !important;
    }
  }
  .send {
    padding-left: 0 !important;
    button {
      margin-right: 15px;
    }
  }
}

/* ==============================================
    CONTACT
================================================*/
#map-canvas {
  width: 100%;
  height: 400px;
}

.contact {
  i {
    margin-right: 3px;
    color: $blueShadow;
  }
  .col-links {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        padding: 0 0 0 20px;
        &:before {
          content: "\f178";
          font-family: fontAwesome;
          color: $blueShadow;
          position: absolute;
          left: 0;
          top: 1px;
        }
      }
    }
  }
}