@media (max-width: $screen-xs-max) {
  h1, h2 {
    font-size:36px;
  }
  section {
    &.title {
      margin:55px 0 0 0;
      padding:15px 0 22px 0;
      h1 {
        &:after {
          content:"";
          width:0;
          height:0;
        }
      }
      h2 {
        font-size:16px;
        text-transform:uppercase;
        margin:0;
        padding:8px 0 0 0;
        &:before,
        &:after {
          content:"";
          width:0;
          height:0;
        }
      }
    }
    &.center {
      h2 {
        font-size:30px;
        padding:30px 0;
      }
    }
  }

  header {
    &.small {
      height:150px;
      .rowWrapper {
        height: 150px;
        .rowInner {
          .logo {
            max-width:240px;
            margin:0 auto;
            img {
              max-width:100%;
            }
          }
        }
      }
    }
  }
  header {
    .rowWrapper {
      .rowInner {
        .logo {
          img {
            max-width:90%;
          }
        }
      }
    }
  }
  .navbar-default {
    top:150px;
  }

  .viewer {
    margin:0 0 30px 0;
  }

  .navbar-default,
  .navbar-home {
    border: 0;
    .navbar-toggle {
      background: $orange;
      border-radius: 0;
      border: 0;
      border-bottom: 3px solid $orangeShadow;
      .icon-bar {
        background: $orangeShadow;
      }
      &:hover,
      &:focus {
        background: $orangeShadow;
        border-bottom: 3px solid $orange;
        .icon-bar {
          background: $orange;
        }
      }
    }
    .navbar-collapse {
      border: 0;
    }
  }
  #menu {
    #navbar {
      ul.navbar-left {
        margin: 0 -15px;
        li {
          a {
            border-top: 1px solid $blueShadow;
            text-align: center;
            &:hover,
            &:focus {
              border-top: 1px solid $blueShadow;
            }
          }
          &.active {
            a {
              border-top: 1px solid $blueShadow;
              &:hover,
              &:focus {
                border-top: 1px solid $blueShadow;
              }
            }
          }
        }
      }
      ul.navbar-right {
        margin: 0 -15px;
        li {
          background:$blueShadow;
          a {
            border-top:1px solid $blue;
            text-align:center;
          }
        }
      }
    }
  }
  .listHouses {
    ul {
      li {
        padding:0 0 26px 0;
        a {
          .image {
            width:100%;
            height:225px;
          }
          .description {
            margin:30px 0 0 0;
            .address {
              margin:0 0 15px 0;
            }
            .price {
              margin:0 0 10px 0;
            }
          }
        }
        &.hired {
          a {
            .image {
              .overlay {
                height:225px;
              }
            }
          }
        }
        &:last-child {
          margin:0;
        }
      }
    }
  }
  .col-sidebar {
    max-width:100%;
    .sidebar {
      max-width:100%;
      margin:0 0 15px 0;
      .margin_bottom {
        margin:0;
      }
      .interested {
        display:none;
      }
      .goBack {
        margin:0;
      }
    }
  }

  .aanbod {
    .overview {
      margin:0 0 15px 0;
      .image {
        height:auto;
      }
      .description {
        display:none
      }
    }
    .table-responsive {
      border:0;
      margin-bottom:0;
    }
    .tabs {
      .tab-content,
      .mapDetails{
        margin-bottom:22px;
      }
      .tab-content {
        form {
          label {
            width:100%;
          }
          [class*="col-"] {
            padding-left:15px;
          }
          .day,
          .month {
            max-width:100%;
          }
          .year {
            max-width:100%;
          }
          .radio {
            width:100%;
            margin-left:0 !important;
          }
          .radioContainer {
            padding-bottom:0;
          }
          h4 {
            margin-bottom:0;
            padding-top:18px;
          }
          .text-area {
            margin-left:0;
            margin-right:0;
          }
          .send {
            margin-right:15px;
          }
        }
      }
    }
  }

  #woningen {
    h2 {
      padding-bottom:20px;
    }
  }
  #meestbekeken {
    h2 {
      padding-top:20px;
    }
  }
  .listAanbod {
    ul {
      li {
        a {
            transition: none;
            .image {
              width:100%;
              transition: none;
              img {
                transition:none;
              }
            }
            .description {
              margin-left:0;
              margin-top:15px;
          }
        }
      }
    }
  }

  footer {
    padding:30px 0;
    ul {
      &.contactgegevens {
        li {
          &:last-child {
            margin:0;
          }
        }
      }
    }
    h3 {
      padding:0 0 5px 0;
    }
  }
  .share {
    margin-left:0;
  }
}